@font-face {
    font-family: 'poppinsregular';
    src: url('fonts/poppins-regular-webfont.woff2') format('woff2'),
         url('fonts/poppins-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
  font-family: 'poppinslight';
  src: url('fonts/poppins-light-webfont.woff2') format('woff2'),
  url('fonts/poppins-light-webfont.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
    font-family: 'poppinsbold';
    src: url('fonts/poppins-bold-webfont.woff2') format('woff2'),
         url('fonts/poppins-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'poppinsmedium';
    src: url('fonts/poppins-medium-webfont.woff2') format('woff2'),
         url('fonts/poppins-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'poppinsextrabold';
    src: url('fonts/poppins-extrabold-webfont.woff2') format('woff2'),
         url('fonts/poppins-extrabold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
}

* {
  box-sizing: border-box;
}

body {
  background: #000;
  font-family: 'poppinsregular';
  background-color: #697ae3;
}

img {
  width: 100%;
  max-width: 100%;
}

a {
  cursor: pointer;
  -webkit-transition: color 0.1s linear 0s; 
    -moz-transition: color 0.1s linear 0s;
  -o-transition: color 0.1s linear 0s;
  transition: color 0.1s linear 0s;
}

header {
  width: 100%;
  display: flex;
  align-items: center;
  background: #000;
  padding: 1rem 2rem;
}

.logo {
    display: flex;
    width: 120px;
}

.container_fluid {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.dp {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5rem;
  padding-left: 0;
  background: linear-gradient(270.16deg, #D9D9D9 18.94%, #7787E1 95.44%);
}

.imageContainer {
  width: 60%;
}

.textContainer {
  width: 40%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

h4 {
  color: #000000;
  font-weight: 500;
  font-size: 40px;
  margin: 0;
}

h1 {
  color: #000000;
  font-weight: 900;
  font-size: 7rem;
  font-family: 'poppinsextrabold';
  margin: 0;
  line-height: 110px;
  text-transform: uppercase;
  display: flex;
  flex-wrap: wrap;
  width: 570px;
  align-items: center;
  justify-content: center;
}

.primary {
  color: #697AE3;
}

.secondary {
  color: #EDAB41;
}

.textContainer p {
  text-transform: uppercase;
  color: #000000;
  font-weight: 500;
  font-size: 16px;
  margin: 0 0 2rem;
  text-align: center;
}

.chat {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  background: #19970e;
  border-radius: 25.947px;
  text-transform: uppercase;
  color: #FFFFFF;
  /*color: #1E1E1E;*/
  font-size: 35px;
  padding: 25px 50px;
  text-decoration: none;
  font-family: 'poppinsmedium';
  margin-top: 5px;
  font-weight: 600;
}

.chat:hover {
  background: #19970e;
  color: #ffffff;
}

.chat img {
  width: 50px;
  height: 50px;
}

footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #000;
  padding: 1rem;
  font-size: 12px;
  color: #b8b8b8;
  text-align: center;
  /*position: fixed;*/
  /*bottom: 0px;*/
  position: fixed;
  bottom: 0px;
  width: 100%;
}

.copRight {
  font-weight: bold;
  color: #fff;
  text-transform: capitalize;
  margin-top: 10px;
}

.mb {
  display: none;
}

.second-line {
  margin-top: 20px !important;
      font-size: 42px !important;
}

@media screen and (max-width: 961px) {
  .second-line-mob {
    margin-top: 10px !important;
      font-size: 25px !important;
      font-weight: 600;
  }
  .container_fluid {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  
  .dp {
    display: none;
  }

  .mb {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #697AE3;
    padding: 1rem;
    flex-grow: 1;
  }

  header {
    width: 176px;
    height: 176px;
    position: absolute;
    top: -88px;
    align-items: flex-end;
    border-radius: 50%;
    padding-bottom: 1.6rem;
  }

  .imageContainer {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .textContainer {
    width: 100%;
    text-align: center;
    background: rgba(217, 217, 217, 0.6);
    padding: 1rem;
    margin-top: 1.5rem;
  }

  .textContainer p {
    font-size: 12px;
    margin-bottom: 1rem;
  }

  h4 {
    font-size: 30px;
  }

  h1 {
    font-size: 4rem;
    line-height: 60px;
    width: 100%;
  }

  .chat {
    background: #19970e;
    border-radius: 12px;
    font-size: 18px;
    padding: 11px 22px;
    font-family: 'poppinsbold';
    gap: 8px;
    margin-top: 0.3rem;
    color: #FFFFFF;
    font-weight: 600;
  }

  .chat img {
    width: 32px;
    height: 32px;
  }

  footer {
    width: 100%;
    font-size: 10px;
    margin-top: auto;
    position: fixed;
    bottom: 0px;
  }
}
